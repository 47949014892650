.contactForm {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: gray;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.formGroup {
  margin-bottom: 15px;
}

.phoneInputGroup {
  display: flex;
}

.countryCodeSelect {
  flex: 0 0 50%; /* Adjust width as needed */
  margin-right: 10px;
  
}

/*textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}*/

input[type='text'],
input[type='email'],
input[type='tel'],
textarea,
select {
width: 100%;
padding: 10px;
box-sizing: border-box;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
}

/*  button {
  background-color: #0d258d;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #0d258d;
  border-radius: 7px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: goldenrod;
}*/

button[type='submit'] {
  width: 100%;
  padding: 10px;
  background-color: #004080;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button[type='submit']:hover {
  background-color: goldenrod;
}

textarea {
  height: 150px; /* Adjust height as per your design */
}

.text-center {
  text-align: center;
}